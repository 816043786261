import React from 'react';

const TopBuys = ({ topBuys, formatTokenAmount, formatWallet }) => {
  return (
    <div className="relative">
      {/* Background image for the hotel section */}
      <div 
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{ backgroundImage: 'url(/bg.png)' }}
      />

      {/* Rooms grid */}
      <div className="relative z-10 max-w-7xl mx-auto p-6">
        <div className="grid grid-cols-4 gap-6">
          {topBuys.map((tx, index) => (
            <div 
              key={tx.id} 
              className="bg-white/90 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden transition-transform hover:transform hover:-translate-y-1"
            >
              {/* Room image */}
              <div className="w-full flex justify-center p-2 bg-gray-50"> {/* Contenedor con padding para la imagen */}
                <img 
                  src={`/${index + 1}.png`} 
                  alt={`Room ${index + 1}`} 
                  className="w-64 h-64 object-contain" /* Cambiado tamaño y object-fit */
                />
              </div>
              
              {/* Room info */}
              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="font-bold text-lg">Room #{index + 1}</span>
                  <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm">
                    Available
                  </span>
                </div>
                
                <div className="space-y-2">
                  <p className="text-blue-600 font-semibold">
                    {formatTokenAmount(tx.tokenAmount)} SOL
                  </p>
                  <p className="text-gray-500 text-sm">
                    Owner: {formatWallet(tx.traderPublicKey)}
                  </p>
                  
                  <a 
                    href={`https://solscan.io/tx/${tx.signature}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block mt-3 text-center bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
                  >
                    View Details
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopBuys;