import React from 'react';
import Header from './components/Header';
import MarketInfo from './components/MarketInfo';
import TopBuys from './components/TopBuys';
import TransactionsSidebar from './components/TransactionsSidebar';
import { formatters } from './utils/formatters';
import { useFirebaseData } from './hooks/useFirebaseData';

const TokenLiveTracker = () => {
  const { transactions, topBuys, marketInfo } = useFirebaseData();

  return (
    <div
      className="min-h-screen relative"
      style={{
        backgroundImage: 'url(/bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
      }}
    >
      <Header />

      <div className="flex pt-16">
        <main className="flex-1 min-h-screen">
          <div className="max-w-6xl mx-auto p-6">
            {/* <MarketInfo
              marketInfo={marketInfo}
              formatSolAmount={formatters.solAmount}
              formatTokenAmount={formatters.tokenAmount}
            /> */}

            <TopBuys
              topBuys={topBuys}
              formatTokenAmount={formatters.tokenAmount}
              formatWallet={formatters.wallet}
            />
          </div>
        </main>

        <TransactionsSidebar
          transactions={transactions}
          formatTokenAmount={formatters.tokenAmount}
          formatWallet={formatters.wallet}
          formatTimestamp={formatters.timestamp}
        />
      </div>
    </div>
  );
};

export default TokenLiveTracker;