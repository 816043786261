export const formatters = {
    solAmount: (amount) => {
      if (!amount || isNaN(amount)) return '0.0000';
      return parseFloat(amount).toFixed(4);
    },
  
    tokenAmount: (amount) => {
      if (!amount || isNaN(amount)) return '0.0000';
      return parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
      });
    },
  
    timestamp: (timestamp) => {
      return new Date(timestamp).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    },
  
    wallet: (wallet) => {
      if (!wallet || typeof wallet !== 'string') return 'Unknown Wallet';
      return `${wallet.slice(0, 4)}...${wallet.slice(-4)}`;
    }
  };
  