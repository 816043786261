import React from 'react';
// import TokenLiveTracker from './components/TokenLiveTracker';
// import TokenLiveTracker from './components/BitqueryTokenTracker'
import TokenLiveTracker from './TokenLiveTracker'

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* <TokenLiveTracker /> */}
      <TokenLiveTracker />
    </div>
  );
}

export default App;